<template>
  <div class="box">
    <!-- <iframe seamless="seamless" noResize frameborder="0"
            src="https://doc2.infox-med.com/31127087.pptx"></iframe> -->
    <iframe seamless="seamless" noResize frameborder="0"
            src="https://view.officeapps.live.com/op/view.aspx?src=https://doc2.infox-med.com/31127087.pptx"></iframe>
  </div>
</template>

<script>
// import 'https://cdn.jsdelivr.net/gh/impress/impress.js@2.0.0/js/impress.js'
export default {
  props: {},
  components: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {
    // window.impress().init()
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.box {
  position: relative;
  width: 100%;
  height: calc(100vh - 200px);
  overflow: hidden;
}
iframe {
  position: absolute;
  // top: -88px;
  width: 100%;
  height: 100vh;
  // height: calc(100% + 88px);
}
</style>
